/**
 * Site header
 */
.site-header {
  // border-top: 5px solid $grey-color-dark;
  // border-bottom: 1px solid $grey-color-light;
  // border-bottom: 1px solid $grey-color-dark;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;

  // background-color: $dsd-dark-grey;
  background: transparent;
}

.site-title-con {
  display: flex;
  width: 341px;
}

.site-title {
  @include relative-font-size(1.625);
  font-size: 1.6rem;
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    // color: $grey-color-dark;
    // color: $dsd-light-grey;
    color: $text-color;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;
  z-index: 3;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    // color: $dsd-light-grey;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  // list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
  font-weight: 700;
  color: $dsd-dark-grey;
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list, .featured-post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.featured-post-list {
  margin-bottom: $spacing-unit * 1.5;
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  color: $dsl-grey;
}

.post-categories, .post-tags {
  font-size: $small-font-size;
  color: $grey-color;
  a, a:visited {
    color: $grey-color;
    text-decoration: underline dotted lighten($grey-color, 20%);
  }
  a:hover {
    color: #E74727;
    text-decoration: underline solid $grey-color;
  }
}

.list-post-categories {
  font-size: $small-font-size;
  color: $grey-color;
  background: #EFF6FF;
  border-radius: 5px;
  padding: 1px 5px;
  a, a:visited {
    color: $grey-color;
    text-decoration: none s;
  }
  a:hover {
    color: #E74727;
    text-decoration: underline solid $grey-color;
  }
}

.list-post-tags {
  font-size: $tiny-font-size;
  color: $grey-color;
  // background: #EFF6FF;
  // border-radius: 5px;
  // padding: 1px 5px;
  a, a:visited {
    color: $grey-color;
    text-decoration: underline dotted lighten($grey-color, 20%);
  }
  a:hover {
    color: #E74727;
    text-decoration: underline solid $grey-color;
  }
}

h3.list-post-title {
  margin-bottom: 0;
}

.post-link {
  // display: block;
  @include relative-font-size(1.5);
  color: $dsd-dark-grey;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 16px;
    background-color: #FFC803;
  }
  &:visited {
    color: $dsd-dark-grey;
  }
  &:hover {
    // text-shadow: 0px 0px 150px #FFC803;
    // color: #FF3E2F;
    color: #003f5c;
    transition: all 0.5s;
  }
  &:hover::after {
    width: 100%; 
    height: 10px;
    transition: width 1s, height 1s;
    opacity: 0.1;
  }
}

$transition-period: 0.3s;
// * {
//   transition: color $transition-period, text-decoration $transition-period, height 3s, text-shadow 3s;
// }



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1.25; // 1

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

p.text-box-1 {
  border: 1px #b8daff solid;
  border-radius: 5px;
  padding: 8px 14px;
  background-color: #cce5ff;
  color: #004085;
}

p.text-box-2 {
  border: 1px #ffeeba solid;
  border-radius: 5px;
  padding: 8px 14px;
  color: #856404;
  background-color: #fff3cd;
}

p.text-box-3 {
  border: 1px #c3e6cb solid;
  border-radius: 5px;
  padding: 8px 14px;
  color: #155724;
  background-color: #d4edda;
}

#markdown-toc::before {
  content: "Contents";
  font-weight: bold;
  font-size: 15px;
  display: block;
  margin-bottom: 12px;
}

// Using numbers instead of bullets for listing
#markdown-toc ul {
  list-style: decimal;
  margin: 0;
}

#markdown-toc {
  font-size: 14px;
  background-color: #f8f9fa;
}

#markdown-toc li {
  margin-bottom: 0;
}

#markdown-toc li a {
  color: #0645ad;
}

#markdown-toc li::before {
  content: "";
}

#markdown-toc {
  border: 1px solid #aaa;
  padding: 1.5em 2em;
  list-style: decimal;
  display: inline-block;
}

$sub-color: #D90000;
$sub-color: #214658;
$sub-color: #BE2805;
$bc: #FEFCF5;
$bc: #eee;
$fc: #333;
$brc: rgba(38, 57, 77, 0.3);
$brc: #777;

.sub-form-cont {
  margin: $spacing-unit auto;
  // background: linear-gradient(180deg, rgba(45, 68, 126, 0.2) 0%, rgba(181, 176, 160, 0.2) 28%, rgba(204, 115, 115, 0.2) 57%, rgba(81, 49, 157, 0.3) 89%);
  // background: linear-gradient(to right, rgba(236, 72, 153, 0.3), rgba(239, 68, 68, 0.3), rgba(245, 158, 11, 0.3));
  // box-shadow: inset 0px 0px 3em 4em #fff;
  padding: $spacing-unit*1.1;
  border-radius: 5px;
  border: 1px solid #222;
  border: 1px solid #222;
  // max-width: 80%;
  border-color: $brc;
  // border-top: 2px solid #ffffff05;
  // box-shadow: rgba(38, 57, 77, 0.7) 0px 10px 30px -10px;
  // $bc1: #fff;
  // $bc2: #588C8C;
  // border-top-color: $bc1;
  // border-left-color: $bc2;
  // border-right-color: $bc2;
  // border-bottom-color: $bc1;

  // border-left: 1px solid #214658;
  // border-right: 1px solid #214658;
  // box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  // background: #e5e0cb;
  background: $bc;
  position: relative;
  box-shadow: 2.5px 2.5px 2px #DC8505, -2.5px -2.5px 2px #717400;
  box-shadow: none;
  transition: box-shadow 5s;
}
.sub-form-cont:hover {
  box-shadow: 2.5px 2.5px 3px #DC8505, -2.5px -2.5px 3px #717400;
}


.sub-form-cont > p {
  // text-align: center;
  font-weight: normal;
  font-weight: 500;
  color: #011C26;
  color: $fc;
  margin-bottom: $spacing-unit*0.707;
  // font-family: Courgette;
  font-size: 19px;
}

.sub-form-cont > p strong {
  color: #EC5519;
}

.sub-form-cont > p .sub-sp1 {
  // color: #dc4e24;
}

.sub-form-cont .sub-img {
  max-width: 45px;
  margin: 0 auto;
  margin-bottom: $spacing-unit/2;
  // position: absolute;
  // left: 0;
  // top: 20px;
}

// Mailchimp subscription
.mc_embed_signup_scroll {
  display: flex;
  justify-content: start;
  align-items: center;
}

.mc_embed_signup_scroll * {
  box-sizing: border-box;
}

.email-cont {
  width: 50%;
}
.mce-EMAIL {
  width: 100%;
  padding: 12px 14px;
  border: 2px solid $sub-color;
  // box-shadow: 0 0 0 3px rgba(0,0,0,.05);
  font-size: 15px;
  // border-radius: 10px 0 0 10px;
  // background: #e5e0cb;
}
.mce-EMAIL:focus {
  outline: none;
}
.mce-EMAIL::placeholder {
  // color: #222;
}

.mc-embedded-subscribe {
  // border-radius: 0 10px 10px 0;
  border: 2px solid $sub-color;
  padding: 12px 10px;
  color: #fff;
  background: $sub-color;
  font-size: 15px;
  font-weight: 700;
  font-weight: 600;
  cursor: pointer;
  transition: background .2s, border-color .2s;
}

.mc-embedded-subscribe:hover {
  background: #EC5519;
  border-color: #EC5519;
  // box-shadow: 0 0 0 3px #e7aa27;
}

.mc-embedded-subscribe-cont .brandingLogo{
  margin: 0;
}

.site-header {
  width: 100%;
}
.site-header > .wrapper {
  max-width: none;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &::after {
    content: none;
  }

  div.nav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  // > * {
  //   width: 16%;
  // }
  // > *:first-child {
  //   width: 42%;
  // }
  // > *:last-child {
  //   width: 42%;
  // }

  div:has(> .site-title) {
    width: 42%;
  }
  div.nav-logo {
    // width: 16%;
    width: fit-content;
  }
  nav.site-nav {
    // width: 42%;
    width: fit-content;
  }
}

.site-header .site-title, .site-header .site-nav {
  float: none;
}

.site-header .site-nav {
  text-align: right;
}

* {
  box-sizing: border-box !important;
}